import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutUsPage = () => (
  <Layout>
    <SEO title="About Us" />
    <h2>About Us</h2>
    <p>Magna consequat sint voluptate est ex veniam dolore commodo ad. Deserunt ipsum ex et amet irure sint id ipsum excepteur excepteur. Ullamco fugiat reprehenderit ipsum commodo Lorem minim et aliquip. Ex enim amet ullamco aliqua aliqua reprehenderit enim est id officia tempor tempor in. Consequat commodo deserunt minim et dolore consectetur incididunt aute.
</p>
<p>
Elit sit qui sit ullamco deserunt officia ex culpa in. Esse do ullamco aliquip nisi sint. Amet adipisicing enim eiusmod sit anim id cupidatat. Mollit qui sint ipsum cupidatat irure fugiat.</p>
  </Layout>
)

export default AboutUsPage
